import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { animateScroll } from 'react-scroll';

import { formatAddress } from 'lib/formatters';
import Link from 'components/common/Link';
import LogoKey from '../../svg/logo-key.svg';

const GET_CAMPAIGN = gql`
  query GetCampaign($id: String!) {
    findCampaigns(id: $id) {
      id
      contactPhone
      contactEmail

      ownerOrganization {
        id
        address1
        address2
        city
        stateProv
        postalCode
        name
        website
      }
    }
  }
`;

const CampaignPageFooter = ({ campaignId }) => {
  const { data } = useQuery(GET_CAMPAIGN, { variables: { id: campaignId } });
  const campaign = useMemo(() => data?.findCampaigns[0], [data]);

  const hasContact = Boolean(campaign?.contactPhone || campaign?.contactEmail);
  const address = formatAddress({
    address1: campaign?.ownerOrganization.address1,
    address2: campaign?.ownerOrganization.address2,
    city: campaign?.ownerOrganization.city,
    stateProv: campaign?.ownerOrganization.stateProv,
    postalCode: campaign?.ownerOrganization.postalCode,
  });

  if (!campaign) return null;

  return (
    <footer>
      <section className="shrink-0 bg-gray-800 text-gray-500 py-12 lg:py-16">
        <div className="container">
          <div className="flex justify-between gap-x-16">
            <div className="grow shrink overflow-hidden">
              <div className="flex flex-col md:flex-row gap-x-16 gap-y-8">
                <div>
                  <h3 className="text-white font-medium">{campaign.ownerOrganization.name}</h3>
                  {address && <p className="mt-2 whitespace-pre-line">{address}</p>}
                  {campaign.ownerOrganization.website && (
                    <p className="mt-2">
                      <Link
                        href={campaign.ownerOrganization.website}
                        className="text-gray-300 hover:text-white transition-colors duration-200 break-all"
                      >
                        {campaign.ownerOrganization.website}
                      </Link>
                    </p>
                  )}
                </div>
                {hasContact && (
                  <div>
                    <h3 className="text-white font-medium">Help &amp; Support</h3>
                    {campaign.contactEmail && (
                      <p className="mt-2">
                        <Link
                          href={`mailto:${campaign.contactEmail}`}
                          className="text-gray-300 hover:text-white transition-colors duration-200 break-all"
                        >
                          {campaign.contactEmail}
                        </Link>
                      </p>
                    )}
                    {campaign.contactPhone && <p className="mt-2">{campaign.contactPhone}</p>}
                  </div>
                )}
              </div>
            </div>
            <div className="shrink-0">
              <button
                type="button"
                className="flex flex-col justify-center items-center group text-white hover:text-gray-400 transition-colors duration-200"
                onClick={() => animateScroll.scrollToTop()}
              >
                <span className="flex justify-center items-center w-12 h-12 rounded-full bg-gray-500 group-hover:bg-gray-600 transition-colors duration-200">
                  <FontAwesomeIcon icon={faChevronUp} size="lg" className="text-gray-800" />
                </span>
                <span className="font-medium uppercase text-xs pt-3">Back to top</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-900 py-10 md:py-6 text-gray-300">
        <div className="container">
          <div className="flex flex-col md:flex-row-reverse -mt-4 justify-between items-center text-center md:text-left">
            <Link
              external
              href="https://pledgeit.org"
              className="lowercase text-lg pt-4 flex items-center"
            >
              Powered by <LogoKey className="fill-current text-white inline-block h-8 ml-3" />
            </Link>
            <p className="text-sm pt-4">
              Copyright &copy;{new Date().getFullYear()} • All rights reserved •{' '}
              <Link
                href="/privacy"
                className="text-gray-300 hover:text-gray-500 transition-colors duration-200"
              >
                Privacy Policy
              </Link>{' '}
              •{' '}
              <Link
                href="/tos"
                className="text-gray-300 hover:text-gray-500 transition-colors duration-200"
              >
                Terms of Use
              </Link>
            </p>
          </div>
        </div>
      </section>
    </footer>
  );
};

CampaignPageFooter.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

export default CampaignPageFooter;
