import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faQuestionCircle,
  faTimes,
  faArrowLeft,
  faCopy,
} from '@fortawesome/pro-regular-svg-icons';

import useFundraiserInviteMessages from 'hooks/useFundraiserInviteMessages';
import useToasts from 'hooks/useToasts';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import Navbar from 'components/common/Navbar';
import NavbarIconButton from 'components/common/NavbarIconButton';
import NavbarTitle from 'components/common/NavbarTitle';
import ToggleMenu from 'components/common/ToggleMenu';
import ToggleMenuItem from 'components/common/ToggleMenuItem';

const FundraiserAdminInviteSelection = ({ fundraiserId, onHide, onSelectEmail, show }) => {
  const toast = useToasts('copy-invite-message');
  const [view, setView] = useState('select'); // select, preview:email, preview:sms
  const { email, sms } = useFundraiserInviteMessages({ fundraiserId });

  const handleCopyMessage = useCallback(() => {
    const textToCopy = view.includes('email') ? email : sms;
    navigator.clipboard.writeText(textToCopy);
    toast.success('Message copied!');
  }, [view, email, sms, toast]);

  return (
    <Modal onHide={onHide} className="md:max-w-md" show={show}>
      <Navbar
        className="sticky z-40 top-0 inset-x-0 h-16 shrink-0 bg-white border-b border-gray-400 px-4 md:px-4"
        left={
          <NavbarIconButton
            as="button"
            type="button"
            icon={view === 'select' ? faTimes : faArrowLeft}
            onClick={view === 'select' ? onHide : () => setView('select')}
            iconProps={{ size: 'lg' }}
          />
        }
        center={<NavbarTitle title={view === 'select' ? 'Invite donors' : 'Message ideas'} />}
      />

      {view === 'select' ? (
        <div className="p-8 space-y-8">
          <p>
            Rally the support of your closest friends and family by sending personalized invitations
            to give to your fundraising page.
          </p>

          <div className="grid grid-cols-1 gap-3">
            <Button
              as="button"
              type="button"
              color="primary"
              onClick={onSelectEmail}
              className="font-medium"
            >
              <div className="flex items-center gap-x-3">
                <FontAwesomeIcon icon={faEnvelope} size="lg" />
                Invite via email
              </div>
            </Button>
            <Button
              as="button"
              type="button"
              color="gray-300"
              onClick={() => setView('preview:sms')}
              className="font-medium"
              outline
            >
              <div className="flex items-center gap-x-3">
                <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
                View message ideas
              </div>
            </Button>
          </div>
        </div>
      ) : (
        <div className="p-8 space-y-6">
          <ToggleMenu className="w-72 mx-auto text-sm">
            <ToggleMenuItem
              isActive={view === 'preview:sms'}
              onClick={() => setView('preview:sms')}
            >
              SMS
            </ToggleMenuItem>
            <ToggleMenuItem
              isActive={view === 'preview:email'}
              onClick={() => setView('preview:email')}
            >
              Email
            </ToggleMenuItem>
          </ToggleMenu>
          <div className="overflow-y-auto hide-scroll border border-gray-400 rounded-md h-64 p-4 text-sm whitespace-pre-line">
            {view === 'preview:sms' ? sms : email}
          </div>
          <Button
            as="span"
            color="primary"
            className="font-medium block w-full cursor-pointer"
            onClick={handleCopyMessage}
          >
            <div className="flex items-center gap-x-3">
              <FontAwesomeIcon icon={faCopy} size="lg" />
              Copy message
            </div>
          </Button>
        </div>
      )}
    </Modal>
  );
};

FundraiserAdminInviteSelection.propTypes = {
  fundraiserId: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onSelectEmail: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

FundraiserAdminInviteSelection.defaultProps = {};

export default FundraiserAdminInviteSelection;
