import { gql, useQuery } from '@apollo/client';

import config from 'config';
import { getFundraiserUrl } from 'lib/getFundraisingUrl';
import { formatCurrency } from 'lib/formatters';

const GET_FUNDRAISER_INVITE_TOKENS = gql`
  query GetFundraiserInviteTokens($fundraiserId: String!) {
    findParticipants(id: $fundraiserId) {
      id
      campaign {
        id
        slug
        isPerformanceEnabled
        name
        ownerOrganization {
          id
          name
        }
        performanceTrackingLevel
      }
      monetaryGoal
      performanceSettings {
        metricLabel
      }
      slug
      resolvedName
    }
  }
`;

const useFundraiserInviteMessages = ({ fundraiserId }) => {
  const { data } = useQuery(GET_FUNDRAISER_INVITE_TOKENS, { variables: { fundraiserId } });
  const fundraiser = data?.findParticipants[0];
  const campaign = fundraiser?.campaign;
  const organization = campaign?.ownerOrganization;

  const campaignName = campaign?.name ?? '[CAMPAIGN_NAME]';
  const organizationName = organization?.name ?? '[ORGANIZATION_NAME]';
  const fundraiserName = fundraiser?.resolvedName ?? '[FUNDRAISER_NAME]';
  const isPerforming =
    campaign?.isPerformanceEnabled && campaign?.performanceTrackingLevel === 'fundraiser';
  const metricLabel = fundraiser?.performanceSettings?.metricLabel ?? '[METRIC_LABEL]';
  const goalAmount = fundraiser ? formatCurrency(fundraiser.monetaryGoal) : '[GOAL_AMOUNT]';
  const fundraiserUrl = fundraiser
    ? getFundraiserUrl({
        fundraiser: fundraiser?.id,
        fundraiserSlug: fundraiser?.slug,
        campaignId: campaign?.id,
        campaignSlug: campaign?.slug,
      })
    : '[FUNDRAISER_URL]';

  return {
    email: `Hi,\n\nI\u0027m excited to share that I\u0027m participating in ${campaignName} benefiting ${organizationName}. Please help me support their important mission by making a donation to my page${
      isPerforming ? ` or pledging per ${metricLabel} during my campaign` : ''
    }. Every gift helps me reach my goal of ${goalAmount}. No gift is too small. I really appreciate your support. Thank you!\n\nSincerely,\n${fundraiserName}`,

    sms: `Hey, I\u0027m raising money for ${organizationName} and it would help a lot if you could share and donate to my campaign. Thank you! Here is a link to my page: ${`${config(
      '/hostname'
    )}${fundraiserUrl}`}`,
  };
};

export default useFundraiserInviteMessages;
