import PropTypes from 'prop-types';
import cx from 'classnames';

const ToggleMenuItem = ({ onClick, isActive, children, className }) => (
  <button
    type="button"
    onClick={onClick}
    className={cx(
      'p-2.5 transition-all duration-200 rounded-xl font-medium',
      {
        'text-gray-700 hover:text-gray-600': !isActive,
        'bg-white shadow-sm text-gray-800': isActive,
      },
      className
    )}
  >
    {children}
  </button>
);

ToggleMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

ToggleMenuItem.defaultProps = {
  isActive: false,
  children: null,
  className: '',
};

export default ToggleMenuItem;
